import { useState } from "react";
import ChatIcon from "../../../assets/icons/chat";

import ResendBulkMessage from "./ResendBulkMessage";
import NewBulkMessage from "./NewBulkMessage";

const BulkMessages = (props) => {
  const [inited, setInited] = useState(false);

  // Because we are NOT fetching from /bulk-messages,
  // you can just render your BulkMessagesDisplay directly.
  return (
    <BulkMessagesDisplay
      inited={inited}
      setInited={setInited}
      {...props}
    />
  );
};

const BulkMessagesDisplay = ({
  // We removed "messages" and "mutate" since we're not fetching them anymore
  displayMessageList,
  selectedMessage,
  unselectedMessage,
  sendNewMessage,
  cancelSendNewMessage,
  inited,
  setInited,
}) => {
  // If you had logic to load or display messages automatically,
  // remove it or leave it as no-op. For example:
  // if (!inited) {
  //   setTimeout(() => {
  //     // No longer calling displayMessageList()
  //     setInited(true);
  //   }, 500);
  // }

  // Keep your existing UI logic for selected or new messages
  // but do NOT show a full list of messages
//   return selectedMessage ? (
//     <ResendBulkMessage
//       {...selectedMessage}
//       cancel={unselectedMessage}
//       setInited={setInited}
//       key={selectedMessage.id}
//     />
//   ) : sendNewMessage ? (
//     <NewBulkMessage
//       cancel={cancelSendNewMessage}
//       setInited={setInited}
//     />
//   ) : (
//     <div className="chat__conversation flex center">
//       <div>
//         <ChatIcon />
//         <p className="fs-20">
//           Select a previous bulk message to view or resend, or send a new bulk
//           message.
//         </p>
//       </div>
//     </div>
//   );
return <NewBulkMessage
      cancel={cancelSendNewMessage}
      setInited={setInited}
    />;
};



export default BulkMessages;
